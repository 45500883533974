import { PlanFeatures } from 'features/features/store/types';

export const noFeatures: PlanFeatures = {
  seoValues: false,
  highlightWords: false,
  synonyms: false,
  sentimentMappersScore: false,
  manualDimensionsScore: false,
  customDimensions: false,
  exportList: false,
  dimensionsScore: false,
  seoAnalysis: false,
  plagiarismChecker: false,
  imagesResolutions: false,
  bestForTextImageQuality: false,
  bestImageQuality: false
};

export const freePlanFeatures: PlanFeatures = {
  seoValues: true,
  highlightWords: true,
  synonyms: true,
  sentimentMappersScore: true,
  manualDimensionsScore: true,
  customDimensions: false,
  exportList: false,
  dimensionsScore: false,
  seoAnalysis: true,
  plagiarismChecker: false,
  imagesResolutions: false,
  bestForTextImageQuality: false,
  bestImageQuality: false
};

export const basicPlanFeatures: PlanFeatures = {
  seoValues: true,
  highlightWords: false,
  synonyms: false,
  sentimentMappersScore: false,
  manualDimensionsScore: false,
  customDimensions: false,
  exportList: false,
  dimensionsScore: false,
  seoAnalysis: true,
  plagiarismChecker: false,
  imagesResolutions: false,
  bestForTextImageQuality: false,
  bestImageQuality: false
};

export const powerVipPlanFeatures: PlanFeatures = {
  seoValues: true,
  highlightWords: true,
  synonyms: true,
  sentimentMappersScore: true,
  manualDimensionsScore: true,
  dimensionsScore: false,
  customDimensions: false,
  exportList: false,
  seoAnalysis: true,
  plagiarismChecker: true,
  imagesResolutions: false,
  bestForTextImageQuality: false,
  bestImageQuality: false
};

export const powerPlanFeatures: PlanFeatures = {
  seoValues: true,
  highlightWords: true,
  synonyms: true,
  sentimentMappersScore: true,
  manualDimensionsScore: true,
  dimensionsScore: false,
  customDimensions: false,
  exportList: false,
  seoAnalysis: true,
  plagiarismChecker: true,
  imagesResolutions: false,
  bestForTextImageQuality: false,
  bestImageQuality: false
};

export const premiumPlanFeatures: PlanFeatures = {
  seoValues: true,
  highlightWords: true,
  synonyms: true,
  sentimentMappersScore: true,
  manualDimensionsScore: true,
  customDimensions: true,
  exportList: true,
  dimensionsScore: true,
  seoAnalysis: true,
  plagiarismChecker: true,
  imagesResolutions: true,
  bestForTextImageQuality: true,
  bestImageQuality: true
};

export const enterprisePlanFeatures: PlanFeatures = {
  seoValues: true,
  highlightWords: true,
  synonyms: true,
  sentimentMappersScore: true,
  manualDimensionsScore: true,
  customDimensions: true,
  exportList: true,
  dimensionsScore: true,
  seoAnalysis: true,
  plagiarismChecker: true,
  imagesResolutions: true,
  bestForTextImageQuality: true,
  bestImageQuality: true
};

export const proPlanFeatures: PlanFeatures = {
  seoValues: true,
  highlightWords: true,
  synonyms: true,
  sentimentMappersScore: true,
  manualDimensionsScore: true,
  dimensionsScore: false,
  customDimensions: false,
  exportList: false,
  seoAnalysis: true,
  plagiarismChecker: true,
  imagesResolutions: true,
  bestForTextImageQuality: true,
  bestImageQuality: false
};

export const businessPlanFeatures: PlanFeatures = {
  seoValues: true,
  highlightWords: true,
  synonyms: true,
  sentimentMappersScore: true,
  manualDimensionsScore: true,
  dimensionsScore: false,
  customDimensions: false,
  exportList: false,
  seoAnalysis: true,
  plagiarismChecker: true,
  imagesResolutions: true,
  bestForTextImageQuality: true,
  bestImageQuality: true
};

export const standardPlanFeatures: PlanFeatures = {
  seoValues: true,
  highlightWords: false,
  synonyms: false,
  sentimentMappersScore: false,
  manualDimensionsScore: false,
  customDimensions: false,
  exportList: false,
  dimensionsScore: false,
  seoAnalysis: false,
  plagiarismChecker: false,
  imagesResolutions: false,
  bestForTextImageQuality: false,
  bestImageQuality: false
};
