import { Box, SelectChangeEvent, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { PersonalityChatSelector } from 'components/personality/PersonalityChatSelector';
import { savePersonalityId } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { useSetDefaultChatPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useSetDefaultPersonality';
import {
  ChatOptimizePromptButton,
  IMPROVE_PROMPT_OUTPUT_TYPE
} from 'features/aiWriter/chat/ChatOptimizePromptButton';
import { GptSelect } from 'features/aiWriter/chat/GptSelect';
import { ActionBox, Separator, TemplateSelect } from 'features/aiWriter/chat/OverviewMessageInput';
import { useGetProjectOrPreselectedLanguageAndCountry } from 'features/aiWriter/hooks/useGetProjectOrPreselectedLanguageAndCountry';
import { MessageConfig } from 'features/homePage/chatForm/CreateChatForm';
import { InformationButton } from 'features/information/apply-information/InformationButton';
import { usePromptOptimizerControlled } from 'features/textGenerator/promptOptimizer/usePromptOptimizerContorlled';
import { usePostHog } from 'posthog-js/react';
import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { GptModel } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import { ChatMessageSource, GAEvents } from 'services/tracking/GAEvents';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';
import { withTestId } from 'utils/utils';

type Props = {
  modelId: string;
  isSending: boolean;
  value: MessageConfig;
  sendButtonConfig: { gtmId: string; disabled?: boolean };

  onChange: (message: MessageConfig) => void;
  onSend: (text: string) => void;
};

export function HomeMessageInput({ modelId, isSending, value, onChange, onSend }: Props) {
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);
  const ignoreBlurRef = useRef(false);
  const translate = useTr();

  const postHog = usePostHog();
  const { mutate: setDefaultPersonality } = useSetDefaultChatPersonality();

  const { text, gptModel, personality } = value;

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (!e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      sendMessage({ source: 'enter' });
    }
  }

  function sendMessage({ source }: { source: ChatMessageSource }) {
    GAEvents.sentChatMessage({ source });
    postHog?.capture('CF - chat message sent via overview');
    onSend(text);
  }

  const { language, country } = useGetProjectOrPreselectedLanguageAndCountry(modelId);

  const params = { language, country };
  useQuery({
    queryKey: httpGetDefaultPersonality.makeQueryKey(params),
    queryFn: () => httpGetDefaultPersonality.callEndpoint(params),
    onSuccess: data => {
      if (data) {
        onChange({ ...value, personality: data });

        return;
      }

      onChange({ ...value, personality: undefined });
    }
  });

  const handleGptModelChange = (event: SelectChangeEvent<unknown>) => {
    inputRef.current?.focus();
    const newModel = event.target.value as GptModel;
    // if upgrade option is selected it has no value therefore we do nothing here
    if (!newModel) {
      return;
    }
    onChange({ ...value, gptModel: newModel });
  };

  const handleTemplateSelect = (template: string) => {
    onChange({ ...value, text: template });
    onSend(template);
    inputRef.current?.focus();

    if (isUndoVisible) {
      setIsUndoVisible(false);
    }
  };

  const handlePersonalitySelect = (personality: PersonalityDto | undefined | null) => {
    onChange({ ...value, personality });
    // important to save personality for chat
    savePersonalityId(personality?.id ?? null);
    inputRef.current?.focus();
    if (personality) {
      setDefaultPersonality({
        personalityId: personality.id,
        country: personality.country,
        language: personality.language
      });
    }
  };

  const handleInformationChange = (informationList: InformationDto[]) => {
    onChange({ ...value, informationList });
  };

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({ ...value, text: e.currentTarget.value });

    if (isUndoVisible) {
      setIsUndoVisible(false);
    }
  };

  const {
    isUndoVisible,
    setInitialPrompt,
    optimizePromptMutation,
    undoPromptOptimization,
    setIsUndoVisible
  } = usePromptOptimizerControlled({
    prompt: text,
    setPrompt: (prompt: string) => onChange({ ...value, text: prompt }),
    locale: { language, country },
    initialPrompt: '',
    outputType: IMPROVE_PROMPT_OUTPUT_TYPE
  });
  const { mutate: optimizePrompt, isLoading: isOptimizationLoading } = optimizePromptMutation;

  const handleOptimizeClick = () => {
    inputRef.current?.focus();
    setInitialPrompt(text);
    optimizePrompt();
  };

  const handleFocus = () => {
    setIsEditorFocused(true);
  };

  const handleBlur = () => {
    // this is required only for the GPT select because for some reason onBlur is triggered before onMouseDown
    // therefore all the icons got hidden before we could even click on them
    setTimeout(() => {
      if (!ignoreBlurRef.current) {
        setIsEditorFocused(false);
      }
      ignoreBlurRef.current = false;
    }, 0);
  };

  const handleIconMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    ignoreBlurRef.current = true;
  };

  return (
    <Box sx={{ position: 'relative', width: '100%' }} {...withTestId('chatflash-element-box')}>
      <StyledTextField
        value={text}
        onChange={handleTextFieldChange}
        onKeyDown={handleKeyDown}
        aria-label={translate('chat.message_input.aria_label')}
        placeholder={translate('chat.message_input.placeholder')}
        disabled={isSending}
        multiline={true}
        minRows={4}
        maxRows={4}
        ref={inputRef}
        fullWidth
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...withTestId('chatflash-text-input-field')}
      />
      {isEditorFocused ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            bottom: '0px',
            left: '0px',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <ActionBox>
            <PersonalityChatSelector
              value={personality}
              onChange={handlePersonalitySelect}
              onClick={() => GAEvents.overviewPersonalitySelectOpen()}
              languageModelId={modelId}
              menuOpeningDirection="bottom"
              onMouseDown={handleIconMouseDown}
              onBlur={handleBlur}
            />
            <Separator />
            <InformationButton
              onMouseDown={handleIconMouseDown}
              onInformationChange={handleInformationChange}
            />
            <Separator />
            <GptSelect
              onClick={() => GAEvents.overviewGptSelectOpen()}
              value={gptModel}
              onChange={handleGptModelChange}
              menuOpeningDirection="bottom"
              onBlur={handleBlur}
              onMouseDown={handleIconMouseDown}
            />
            <Separator />
            <TemplateSelect
              modelId={modelId}
              onSelection={handleTemplateSelect}
              onMouseDown={handleIconMouseDown}
            />
          </ActionBox>

          <ChatOptimizePromptButton
            onMouseDown={handleIconMouseDown}
            prompt={text}
            undoVisible={isUndoVisible}
            loading={isOptimizationLoading}
            onOptimizeClick={handleOptimizeClick}
            onUndoClick={undoPromptOptimization}
          />
        </Box>
      ) : null}
    </Box>
  );
}

const StyledTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.colors.commonWhiteMain};

  .MuiInputBase-root {
    textarea {
      margin-bottom: 22px;
    }
  }
`;
