import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { IconButton } from '@mui/material';
import { MouseEvent } from 'react';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import { withTestId } from 'utils/utils';

export function CommandTemplatesButton(props: {
  gtmId: string;
  onSelection: () => void;
  onMouseDown?: (e: MouseEvent) => void;
}) {
  const { gtmId, onSelection, onMouseDown } = props;
  return (
    <StyledButton
      {...withGtmInteraction(gtmId)}
      onClick={onSelection}
      size="small"
      onMouseDown={onMouseDown}
      {...withTestId('command-templates-button')}
    >
      <StickyNote2Icon fontSize="small" />
    </StyledButton>
  );
}

const StyledButton = styled(IconButton)`
  cursor: pointer;
  border: none;
  color: ${({ theme }) => theme.colors.componentsIconActive};

  &:hover {
    border: none;
    background-color: unset;
  }
`;
