import { forwardRef, ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled, { css } from 'styled-components';
import { objectToCss } from 'utils/objectToCss';
import { withTestId } from 'utils/utils';

const RightActionsBox = styled.div`
  justify-self: flex-end;
  visibility: hidden;
`;

const Root = styled.div`
  display: grid;
  position: relative;
  z-index: 0;

  &:hover ${RightActionsBox} {
    visibility: visible;
  }
`;

const SurfaceBox = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  z-index: -1;
`;

/**
 * In case the tile surface is clickable we want to pass events to it.
 * However there are some content elements that still should be interactive
 * `pre` is here, because it is related to code blocks and they can be scrollable
 */
const ignorePointerEvents = css`
  pointer-events: none;

  a,
  button,
  pre {
    pointer-events: auto;
  }
`;

const ActionsBox = styled.div<{ $amountActions: number }>`
  ${ignorePointerEvents};
  display: grid;
  grid-template-columns: ${({ $amountActions }) => `repeat(${$amountActions}, 1fr)`};
  align-items: center;
  padding: ${({ theme }) => theme.spacings.small};
  padding-top: ${({ theme }) => theme.spacings.xsmall};
`;

const LeftActionsBox = styled.div`
  justify-self: flex-start;
`;

const MarkdownBox = styled.div`
  ${ignorePointerEvents};
  /**
   * This is here to enforce text wrapping and code block scroll
   * This works because of 'display: grid' on parent
   */
  min-width: 0;
`;

/**
 * Part of the styles comes from global styles defined in Bootstrap
 * hence we need to override those to match our desired font size in chat
 */
export const BasicMarkdownPreview = styled(ReactMarkdown).attrs({ remarkPlugins: [remarkGfm] })`
  padding: ${({ theme }) => theme.spacings.medium};
  padding-bottom: 0;
  word-break: break-word;
  font-size: ${({ theme }) => theme.fontSizes.body2};

  p {
    white-space: pre-wrap;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  pre {
    font-size: inherit;
  }

  h1 {
    all: unset;
    display: block;
    margin: ${({ theme }) => theme.spacings.small} 0;
    ${({ theme }) => objectToCss(theme.typography.h4)};
  }

  h2 {
    all: unset;
    display: block;
    margin: ${({ theme }) => theme.spacings.small} 0;
    ${({ theme }) => objectToCss(theme.typography.h5)};
  }

  // h4 - h6 usually doesn't exists in markdown, therefore we're styling them like h3
  h3,
  h4,
  h5,
  h6 {
    all: unset;
    display: block;
    margin: ${({ theme }) => theme.spacings.small} 0;
    ${({ theme }) => objectToCss(theme.typography.h6)};
  }

  ol,
  ul {
    all: revert;
    margin: 0;
    padding-inline-start: 24px;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: ${({ theme }) => theme.spacings.small};
    border: 1px solid ${({ theme }) => theme.colors.aiWriterSidebarBorderColor};
  }
`;

const neutralSurfaceStyles = css`
  background-color: ${({ theme }) => theme.colors.secondaryColorHover};
  box-shadow: none;
  border-radius: ${({ theme }) => theme.borderRadius.one};
`;

const noMessageSurfaceStyles = css`
  background-color: ${({ theme }) => theme.colors.surfaceCard};
  box-shadow: none;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: 1rem;
`;

export const NoMessageSurface = styled.div`
  ${noMessageSurfaceStyles};
`;

export const NeutralSurface = styled.div`
  ${neutralSurfaceStyles};
`;

export const NeutralButtonSurface = styled.button`
  all: unset;
  ${neutralSurfaceStyles};

  :not([disabled]) {
    cursor: pointer;

    &:hover,
    &:focus-visible {
      background-color: ${({ theme }) => theme.colors.secondaryColorFocus};
      box-shadow: ${({ theme }) => theme.shadow.textSuggestionHover};
    }

    &:active {
      box-shadow: none;
    }
  }
`;

type Props = {
  markdown: ReactNode;
  surface: ReactNode;
  rightActions?: ReactNode;
  leftActions?: ReactNode;
  children?: ReactNode;
};

export const MarkdownTile = forwardRef<HTMLDivElement, Props>(
  ({ children, surface, markdown, rightActions = null, leftActions = null }, ref) => (
    <Root ref={ref}>
      <MarkdownBox {...withTestId('markdown-box')}
      >{markdown}</MarkdownBox>
      <SurfaceBox {...withTestId('surface-box')}
      >{surface}</SurfaceBox>
      {(rightActions || leftActions) && (
        <ActionsBox $amountActions={(leftActions ? 1 : 0) + (rightActions ? 1 : 0)}>
          {leftActions && <LeftActionsBox>{leftActions}</LeftActionsBox>}
          {rightActions && <RightActionsBox>{rightActions}</RightActionsBox>}
        </ActionsBox>
      )}

      {children}
    </Root>
  )
);
