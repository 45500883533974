import { CircularProgress, Link, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ReactComponent as IconChat } from 'assets/icons/ai-writer-sidebar/icon-chat-flash.svg';
import FlexContainer from 'components/FlexContainer';
import LocalStorageKey from 'config/localStorageKey';
import { getRoutePath } from 'config/routes';
import { predefineMessageDraft } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { allowedPricingPlans } from 'features/aiWriter/chat/GptSelect';
import { HomeChatModelSelector } from 'features/aiWriter/chat/HomeChatModelSelector';
import { HomeMessageInput } from 'features/aiWriter/chat/HomeMessageInput';
import { setCurrentTab } from 'features/aiWriter/store/actions/tabs/actions';
import { configureChatTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/configureChatTabThunk';
import { initializeTabThunk } from 'features/aiWriter/store/actions/tabs/thunks/initializeTabThunk';
import { fallbackModelCountry } from 'features/aiWriter/store/utils/defaults/fallbackModelCountry';
import { fallbackModelLanguage } from 'features/aiWriter/store/utils/defaults/fallbackModelLanguage';
import { getPreferredAudience } from 'features/aiWriter/utils/getPreferredAudience';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import useGetModelAudiences from 'features/audiences/hooks/useGetModelAudiences';
import { getGetAudienceByLanguageAndCountry } from 'features/audiences/store/selectors';
import getAudienceInitValue from 'features/audiences/utils/getAudienceInitValue';
import { usePreferredLocaleQuery } from 'features/customerPreferences/usePreferredLocaleQuery';
import { getEmbeddingModelsByLanguageAndAudience } from 'features/embeddingModels/store/selectors';
import { EmbeddingModel } from 'features/embeddingModels/store/types';
import FormattedMessage from 'features/i18n/FormattedMessage';
import { getPricingPlan } from 'features/pricing/store/selectors';
import { fetchDefaultOutputTypeByLanguageCountry } from 'features/textGenerator/utils/useGetPromptsOthers';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import {
  GPT_MODELS,
  GptModel
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { assertNonNullable } from 'utils/typescript/nonNullable';
import { withTestId } from 'utils/utils';

type ModelAndAudience = {
  modelId: string;
  audienceId: number;
};

function useModelAndAudience() {
  const [modelAndAudience, setModelAndAudience] = useState<ModelAndAudience>();
  const preferencesResult = usePreferredLocaleQuery();
  const preferredLocale = preferencesResult.data;
  const language = preferredLocale?.language ?? fallbackModelLanguage;
  const country = preferredLocale?.country ?? fallbackModelCountry;
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);
  const initialModelId = models.find(
    model => model.language === language && model.country === country
  )?.id;
  const getAudienceByLanguageAndCountry = useAppSelector(getGetAudienceByLanguageAndCountry);
  const audiences = getAudienceByLanguageAndCountry(language, country);
  const initialAudienceId = getPreferredAudience({ audiences, locale: { language, country } })?.id;

  const canSetInitialData = !modelAndAudience && preferencesResult.isFetched;
  useEffect(() => {
    if (canSetInitialData) {
      setModelAndAudience({
        modelId: initialModelId ?? '',
        audienceId: initialAudienceId ?? 0
      });
    }
  }, [canSetInitialData, initialAudienceId, initialModelId]);

  return {
    modelAndAudience,
    setModelAndAudience,
    isModelAndAudienceLoading: preferencesResult.isLoading
  };
}

export type MessageConfig = {
  text: string;
  personality?: PersonalityDto | null;
  gptModel: GptModel;
  informationList?: InformationDto[];
};

export type Language = {
  language?: string;
  country?: string;
};

const LanguageField = (props: {
  name: string;
  models: EmbeddingModel[];
  onSubmit: (values: Language) => void;
}) => {
  const { name, models, onSubmit } = props;
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext<Language>();

  const handleLanguage = (model: string) => {
    setFieldValue('language', model);
    onSubmit({ language: model });
  };

  const textFieldStyles = { '.MuiOutlinedInput-notchedOutline': { border: 'none !important' } };
  const autocompleteStyles = { '.MuiAutocomplete-endAdornment': { display: 'none' } };

  return (
    <HomeChatModelSelector
      models={models}
      {...field}
      onSelect={handleLanguage}
      size="small"
      isSaveAsDefaultShown={true}
      customTextFieldStyles={textFieldStyles}
      customAutocompleteStyles={autocompleteStyles}
    />
  );
};

export const aiWriterWithoutNewDocumentParameterName = 'skipNewDocument';

export function CreateChatForm() {
  const navigate = useNavigate();
  const { modelAndAudience, setModelAndAudience, isModelAndAudienceLoading } =
    useModelAndAudience();
  const models = useAppSelector(getEmbeddingModelsByLanguageAndAudience);

  const initialValues: Language = {
    language: modelAndAudience?.modelId,
    country: fallbackModelCountry
  };

  const storedGptModel =
    (localStorage.getItem(LocalStorageKey.DefaultGptModel) as GptModel) ?? GPT_MODELS.GPT_3_5;

  const pricingPlan = useAppSelector(getPricingPlan);
  const isAllowedToSelectGpt4 = allowedPricingPlans.includes(pricingPlan);

  const initGptModel = isAllowedToSelectGpt4 ? storedGptModel : GPT_MODELS.GPT_3_5;

  const initialMessageConfig: MessageConfig = {
    text: '',
    gptModel: initGptModel
  };

  const [messageConfig, setMessageConfig] = useState<MessageConfig>(initialMessageConfig);

  const dispatch = useAppDispatch();

  const { mutate: initializeTab, isLoading: isSending } = useMutation({
    mutationFn: async (embeddingModelId: string) => {
      const audience = getModelAudiences(embeddingModelId)[0];
      assertNonNullable(audience.language, 'Audience language falsy');
      assertNonNullable(audience.country, 'Audience country falsy');

      const outputType = await fetchDefaultOutputTypeByLanguageCountry(
        audience.language,
        audience.country
      );

      await dispatch(
        initializeTabThunk(
          {
            embeddingModelId: embeddingModelId,
            audienceId: audience.id,
            name: unnamed,
            isNewDocument: true,
            outputType,
            brief: '',
            keywords: '',
            keywords2: '',
            tonality: [],
            personalityId: messageConfig.personality?.id
          },
          configureChatTabThunk,
          { shouldCollapseSidebar: false }
        )
      );
    }
  });

  async function onSubmit(text: string) {
    const { gptModel, personality, informationList } = messageConfig;
    if (!modelAndAudience) {
      return;
    }

    localStorage.setItem(LocalStorageKey.DefaultGptModel, gptModel);

    // this is required to reset the current project in order to start a new chat rather than send a message to an old one
    dispatch(setCurrentTab(''));

    await navigate(`${getRoutePath('aiWriter')}?${aiWriterWithoutNewDocumentParameterName}=true`);

    predefineMessageDraft({
      text,
      gptModel,
      personalityId: personality?.id ?? null,
      informationList: informationList
    });

    const embeddingModelId = modelAndAudience?.modelId;
    initializeTab(embeddingModelId);
  }

  const getModelAudiences = useGetModelAudiences();

  const onModelSubmit = (values: Language) => {
    const embeddingModelId = models.find(model => model.id === values.language)?.id;

    assertNonNullable(embeddingModelId, 'Model not found');

    const audiences = getModelAudiences(embeddingModelId);
    const audienceId = getAudienceInitValue(audiences, LocalStorageKey.AiWriterProject) ?? 0;

    setModelAndAudience({ modelId: embeddingModelId, audienceId });
  };

  if (!modelAndAudience) {
    return null;
  }

  return (
    <Root>
      <Header {...withTestId('chatflash-element')}>
        <FlexContainer direction="row" gap="two" alignItems="center">
          <IconChat />
          <StyledTypography variant="body1">
            <FormattedMessage id="home.chatFlash.title" />
          </StyledTypography>
        </FlexContainer>
        {isModelAndAudienceLoading ? (
          <CircularProgress size={20} />
        ) : (
          <Formik initialValues={initialValues} onSubmit={onModelSubmit}>
            <Form>
              <ModelInputContainer>
                <LanguageField models={models} name="language" onSubmit={onModelSubmit} />
              </ModelInputContainer>
            </Form>
          </Formik>
        )}
      </Header>
      <HomeMessageInput
        modelId={modelAndAudience.modelId}
        sendButtonConfig={{
          gtmId: gtmIds.aiWriter.projectOverview.chat.sendMessage,
          disabled: !modelAndAudience
        }}
        isSending={isSending}
        value={messageConfig}
        onChange={message => setMessageConfig(message)}
        onSend={onSubmit}
      />
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spacings.seven}`};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.two};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTypography = styled(Typography)`
  line-height: 0px;
`;

const ModelInputContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  width: 15rem;
`;

export const StyledTooltipLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryColorLight};
  &:hover {
    color: ${({ theme }) => theme.colors.primaryColorLight};
  }
` as typeof Link;
